<template>
  <v-container id="dashboard" fluid tag="section">

    <validation-observer v-slot="{ valid }">
      <base-material-wizard v-model="tab" :available-steps="availableSteps" :validated-steps="validatedSteps" :items="tabs" 
      class="mx-auto" @click:next="next(valid)" @click:prev="prev" @change="changed" @close="close" :nextDisabled="nextDisabled">
        <slot slot="header"><p>Welcome to Openstage, you are just a few clicks away from selling tickets for your event. </p>
        </slot>
        <v-tab-item class="pb-12">
          <v-form ref="0">
            <div v-if="!boxOffice" class="mx-auto display-1 font-weight-light" justify="space-around" style="max-width: 500px;">
              Your Box Office is the home for all your events.  It should be the name of your organisation.  You can change it at any time.
             <v-text-field v-model="boxOfficeName" color="secondary" label="Box Office Name" prepend-icon="mdi-ticket-account" :rules="[utils.requiredRule]"/>
            </div>
            <div class="mx-auto mt-5 display-1 font-weight-light" justify="space-around" style="max-width: 500px;">
              Please give your event a name, this will be prominent on the page where people buy tickets.  You can change it at any time.
             <v-text-field v-model="eventName" color="secondary" label="Event Name" prepend-icon="mdi-calendar-heart" :rules="[utils.requiredRule]"/>
            </div>
            <div class="mx-auto mt-5 display-1 font-weight-light" justify="space-around" style="max-width: 500px;">
              And, confirm your email address for correspondance with your customers.
             <v-text-field v-model="email" color="secondary" label="Email" prepend-icon="mdi-email" :rules="[utils.emailRule]"/>
            </div>
            <!-- <div class="mx-auto mt-5 display-1 font-weight-light" justify="space-around" style="max-width: 500px;">
              And, phone number, this will not be communicated to your customers.
             <v-text-field v-model="phone" color="secondary" label="Phone" prepend-icon="mdi-phone"/>
            </div> -->
          </v-form>
        </v-tab-item>

        <v-tab-item class="pb-12">
          <v-form ref="1">
            <div class="mx-auto display-1 font-weight-light" justify="space-around" style="max-width: 500px;">
              Where will your event be?  Enter a postcode to help your customers find the event easily.
             <v-text-field v-model="location" color="secondary" label="Location" prepend-icon="mdi-map-marker"/>
            </div>
            <div class="mx-auto mt-5 display-1 font-weight-light" justify="space-around" style="max-width: 500px;">
              When will your event start and end?
              <div class="mt-2 d-flex">
                <v-icon class="mt-n3">mdi-calendar</v-icon>
                <div :class="{'d-flex': $vuetify.breakpoint.smAndUp}">
                  <div class="d-flex">
                    <span class="ml-3 mr-2 small body-1">Start</span>
                    <div>   
                      <datetime auto input-id="start" class="mb-0" input-style="border-bottom:1px solid" v-model="start" type="datetime" :max-datetime="end"></datetime>
                      <span class="small body-2" style="color:red">{{startError}}</span>
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="ml-3 mr-2 small body-1">End</span>
                    <div>   
                      <datetime auto input-id="end" input-style="border-bottom:1px solid" v-model="end" type="datetime" :min-datetime="start"></datetime>
                      <span id="endError" class="small body-2" style="color:red">{{endError}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-auto mt-5 display-1 font-weight-light" justify="space-around" style="max-width: 500px;">
              Upload an image for your event.  You can change this later on your Event Details page.
              <div>
                <img v-if="eventImage" :src="getCloudinaryImage({url: eventImage, width: 200})"> 
                <v-btn v-else @click="uploadImage()">Upload</v-btn>
              </div>
            </div>
          </v-form>
        </v-tab-item>

        <v-tab-item class="pb-12">
          <v-form ref="2">
            <div class="mx-auto display-1 font-weight-light" justify="space-around" style="max-width: 500px;">
              Each event can have several ticket types.  For example, you could have Paid Tickets and Free Tickets.  
              You can add more ticket types later, but for now, please just enter a name for the main type of ticket for your event.
             <v-text-field v-model="ticketName" color="secondary" label="Ticket Name" prepend-icon="mdi-ticket" :rules="[utils.requiredRule]"/>
            </div>
            <div class="mx-auto mt-5 display-1 font-weight-light" justify="space-around" style="max-width: 500px;">
              How many tickets are available for your event?  If you are not sure yet, don't worry, you can change this at any time.
             <v-text-field v-model="quantity" color="secondary" label="Number of Tickets" prepend-icon="mdi-ticket-outline" :rules="[utils.integerRule]"/>
            </div>
            <div v-if="boxOffice && boxOffice.stripeId" class="mx-auto mt-5 display-1 font-weight-light" justify="space-around" style="max-width: 500px;">
              How much will you charge for each ticket?  You can add things like booking fees later.
             <v-text-field :prefix="getCurrencyPrefix(boxOffice.currency)" v-model="price" color="secondary" label="Ticket Price" prepend-icon="mdi-cash" :rules="[priceRule]"/>
            </div>
          </v-form>
        </v-tab-item>

        <v-tab-item class="pb-12">
          <v-form ref="3">
            <div class="mx-auto display-1 font-weight-light" justify="space-around" style="max-width: 500px;">
              <p>Congratuations, you have set up your first event. </p>
              <p v-if="boxOffice && event">Click <v-btn small color="primary" :href="makeURL()" target="_blank">Preview</v-btn> to checkout what your <b>Event Page</b> 
                looks like so far.  Don't worry, only you can see it for the time being.  You need to <b>Publish</b> it 
                before other people can buy tickets, but you can test the ordering process.</p>
              <p>Click <v-btn @click="finish" small color="success">Finish</v-btn> to customise your <b>Event Page</b> and <b>Publish</b> it when you are ready.</p>
            </div>
          </v-form>
        </v-tab-item>
      </base-material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import Api from '@/services/Api.js'; 
import Utils from '@/services/Utils.js';
import EventUtils from '@/services/EventUtils.js';
import Store from '@/services/Store.js';
import { getCloudinaryImage } from '@/helpers/cloudinary'
import { getCurrencyPrefix } from '@/helpers/currency'

  export default {
    name: 'DashboardFormsWizard',

    components: {
      BaseMaterialWizard: () => import('@/components/base/MaterialWizard'),
      SelectImageButton: () => import('./components/SelectImageButton'),
    },  

    data: () => ({
      getCurrencyPrefix,
      getCloudinaryImage,
      nextDisabled: false,
      currentBoxOfficeId: Store.boxOffice.id,
      boxOffice: null,
      eventImage: "",
      event: null,
      eventTime: null,
      ticketType: null,
      eventName: "",
      ticketName: "",
      boxOfficeName: "",
      email: "",
      phone: "",
      price: null,
      quantity: null,
      location: "",
      start: null,
      end: null,
      startError: null,
      endError: null,
      tab: 0,
      tabs: ['About', 'Details', 'Tickets', 'Check'],
      newBoxOfficeName: "",
      boxOffices: [],
      valid: false,
      first: "",
      last: "",
      showCreateBoxOfficeToggle: false,
    }),

    created() {
      var cookieTab = this.$cookies.get("wizardTab");
      if (cookieTab) {
        this.tab = parseInt(cookieTab);
      }
      if (this.user) {
        this.email = this.user.email;
      }
      var id = this.$route.params.id;
      if (!id) {
        id = this.$cookies.get("boxOfficeId");
      }
      this.load(id); 
    },

    computed: {
      availableSteps () {
        if (this.boxOffice && this.event) {
          return [0,1,2,3,4];
        } else {
          return [0];
        }
      },
      user () {
        return this.$store.state.auth.userAuth
      },
      artistId () {
        return this.$store.state.auth.artistId
      },

      validatedSteps () {
        return [0,1,2,3,4];
      },

      utils: function () {
        return Utils;
      },

    },
    
    methods: {

      connected(boxOffice) {
        this.boxOffice = boxOffice;
      },

      async load(id) {
        if (!this.user) { 
          return
          // this.user = {};
          // this.user.email = this.email;
          // //ATS - this.user.name = this.$auth.user.name;
          // this.user = await Api.post(this, "User", "create", this.user);
        }
        this.phone = this.user.phone;
        if (id) {
          var newBoxOffice = {};
          newBoxOffice.id = id;
          this.boxOffice = await Api.post(this, "BoxOffice", "read", newBoxOffice);
        } else {
          this.boxOffice = await Api.first(this, "BoxOffice", {artistId: this.artistId}, true);
        }
        if (this.boxOffice) {
          this.boxOfficeName = this.boxOffice.name;
          this.email = this.boxOffice.email;
          Store.store(this.boxOffice);
          this.$cookies.set("boxOfficeId", this.boxOffice.id);
        }

        var cookieEventId = this.$cookies.get("eventId");
        if (cookieEventId) {
          var readEvent = {};
          readEvent.id = cookieEventId;
          this.event = await Api.post(this, "Event", "read", readEvent, true);
          if (this.event) {
            this.eventName = this.event.name;
            this.location = this.event.location;
            this.eventImage = this.event.eventImage;
            this.$cookies.set("eventId", this.event.id);
          }
        }

        var cookieEventTimeId = this.$cookies.get("eventTimeId");
        if (cookieEventTimeId) {
          var readEventTime = {};
          readEventTime.id = cookieEventTimeId;
          this.eventTime = await Api.post(this, "EventTime", "read", readEventTime, true);
          if (this.eventTime) {
            this.start = Utils.dateToIso(this.eventTime.start);
            this.end = Utils.dateToIso(this.eventTime.end);
            this.$cookies.set("eventTimeId", this.eventTime.id);
          }
        }

        var cookieTicketTypeId = this.$cookies.get("ticketTypeId");
        if (cookieTicketTypeId) {
          var readTicketType = {};
          readTicketType.id = cookieTicketTypeId;
          this.ticketType = await Api.post(this, "TicketType", "read", readTicketType, true);
          if (this.ticketType) {
            this.ticketName = this.ticketType.name;
            this.price = this.ticketType.price;
            this.quantity = this.ticketType.quantity;
            this.$cookies.set("ticketTypeId", this.ticketType.id);
          }
        }
      },

      prev() {
        this.tab--;
        this.changed(this.tab);
      },

      changed(number) {
        this.$cookies.set("wizardTab", number);
      },

      uploadImage(image) {
        var uploader = window.cloudinary.createUploadWidget({
          cloudName: "dimyv3wy5", 
          folder: "ticket/"+this.boxOffice.id,
          uploadPreset: 'tickets',
          cropping: true
          }, (error, result) => { 
            if (error) {
              this.$toast.error(" ", error.message, {position: 'bottomCenter', theme: 'dark', backgroundColor : "red"});
            }
            if (result && result.event === "success") {
              this.eventImage = result.info.secure_url;
            }
          });
        uploader.open();
      },

      selectEventImage(image) {
        this.eventImage = image;
        this.event.eventImage = image;
      },

      priceRule(value) {
        return Utils.priceRuleAndCheckStripe(this.boxOffice, value);
      },

      finish() {
        Store.boxOffice.name = this.boxOffice.name;
        Store.boxOffice.id = this.boxOffice.id;
        this.$router.push("/Dashboard/EventDetails/" + this.event.id)
      },

      close() {
        this.clearCookies();
        this.$router.push("/Dashboard/BoxOffices/");
      },

      clearCookies() {
        Utils.clearCookies(this);
      },

      makeURL() {
        return EventUtils.makeURL(this.boxOffice, this.event);
      },

      editStripe() {
        if (this.boxOffice && this.boxOffice.stripeId) {
          return false;
        }
        return true;
      },

      async next () {
        var form = this.$refs[this.tab];
        // console.log(form)
        if (! await Utils.validate(form) ) {
          this.nextDisabled = false;
          return;
        }
        this.nextDisabled = true;
        if (this.tab == 0) {
          // this.user.phone = this.phone
          // this.user = await Api.post(this, "User", "update", this.user);
          if (!this.boxOffice) {
            var newBoxOffice = {};
            newBoxOffice.name = this.boxOfficeName;
            newBoxOffice.email = this.email;
            newBoxOffice.artistId = this.artistId;
            this.boxOffice = await Api.post(this, "BoxOffice", "create", newBoxOffice);
          } else {
            this.boxOffice.name = this.boxOfficeName;
            this.boxOffice.email = this.email;
            this.boxOffice = await Api.post(this, "BoxOffice", "update", this.boxOffice);
          }
          if (!this.event) {
            var newEvent = {};
            newEvent = {};
            newEvent.name = this.eventName;
            newEvent.email = this.boxOffice.email;
            newEvent.boxOfficeId = this.boxOffice.id;
            this.event = await Api.post(this, "Event", "create", newEvent);
          } else {
            this.event.name = this.eventName;
            this.event.email = this.boxOffice.email;
            this.event = await Api.post(this, "Event", "update", this.event);
          }
          this.$cookies.set("boxOfficeId", this.boxOffice.id);
          this.$cookies.set("eventId", this.event.id);
        }

        if (this.tab == 1) {
          if (!this.start) {
            this.startError = "please enter a start time";
            this.nextDisabled = false;
            return;
          }
          this.startError = "";
          if (!this.end) {
            this.endError = "please enter an end time";
            this.nextDisabled = false;
            return;
          }
          this.endError = "";

          this.event.location = this.location;
          this.event.eventImage = this.eventImage;
          this.event.thumbnail = this.eventImage;
          this.event = await Api.post(this, "Event", "update", this.event);
          if (!this.eventTime) {
            this.eventTime = {};
            this.eventTime.eventId = this.event.id;
            this.eventTime.start = Utils.dateToDateTime(this.start);
            this.eventTime.end = Utils.dateToDateTime(this.end);
            this.eventTime = await Api.post(this, "EventTime", "create", this.eventTime);
          } else {
            this.eventTime.start = Utils.dateToDateTime(this.start);
            this.eventTime.end = Utils.dateToDateTime(this.end);
            this.eventTime = await Api.post(this, "EventTime", "update", this.eventTime);
          }
          this.$cookies.set("eventTimeId", this.eventTime.id);
        }

        if (this.tab == 3) {
          if (!this.ticketType) {
            var newticketType = {};
            newticketType.name = this.ticketName;
            newticketType.quantity = this.quantity;
            if (this.price) {
              newticketType.price = this.price;
            }
            newticketType.eventId = this.event.id;
            newticketType.boxOfficeId = this.boxOffice.id;
            this.ticketType = await Api.post(this, "TicketType", "create", newticketType);
          } else {
            this.ticketType.name = this.ticketName;
            this.ticketType.quantity = this.quantity;
            if (this.price) {
              this.ticketType.price = this.price;
            }
            this.ticketType = await Api.post(this, "TicketType", "update", this.ticketType);
          }
          this.$cookies.set("ticketTypeId", this.ticketType.id);
        }

        this.tab++
        if (this.tab == 4) {
          this.finish();
        }

        this.changed(this.tab);
        this.nextDisabled = false;
      },
    }
  }

</script>

<style lang="sass">
  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%

</style>
